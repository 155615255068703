import { GetObjectCommand, S3Client, S3ClientConfig } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { BucketName } from 'common/types'
import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { s3BucketRegion } from 'shared/types/aws'
import { useFirebase } from './hooks/useFirebase'

type S3ContextProps = {
  s3Client: S3Client
  bucketName: BucketName
}

const S3Context = createContext<S3ContextProps | null>(null)

type S3ProviderProps = {
  children: ReactNode
  bucketName: BucketName
}

export const S3Provider: React.FC<S3ProviderProps> = ({
  children,
  bucketName,
}) => {
  const { loading, error, data } = useFirebase('connections/aws')

  const s3Client = useMemo(() => {
    if (data === null) return null

    const { accessKeyId, secretAccessKey } = data

    const s3ClientConfig: S3ClientConfig = {
      credentials: { accessKeyId, secretAccessKey },
      region: s3BucketRegion,
    }

    return new S3Client(s3ClientConfig)
  }, [data])

  if (loading) {
    return <div className="py-12 text-center">Chargement...</div>
  }

  if (error || !s3Client) {
    return <div className="py-12 text-center">Erreur</div>
  }

  const contextValue: S3ContextProps = {
    s3Client,
    bucketName,
  }

  return (
    <S3Context.Provider value={contextValue}>{children}</S3Context.Provider>
  )
}

// Hook to use the S3 context and utils
export const useS3Context = (): S3ContextProps & {
  getS3Url: (key: string) => Promise<string>
} => {
  const context = useContext(S3Context)
  if (!context) {
    throw new Error('useS3Context must be used within an S3Provider')
  }
  const { s3Client, bucketName } = context

  const getS3Url = useCallback(
    async (key: string): Promise<string> => {
      if (s3Client === null) {
        throw new Error('S3 client is not initialized')
      }
      const command = new GetObjectCommand({ Bucket: bucketName, Key: key })
      const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 })
      return url
    },
    [bucketName, s3Client],
  )

  return { s3Client, bucketName, getS3Url }
}
