import React from 'react'
import { cn } from 'shared/utils/web/cn'

export interface ButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {
  primary?: boolean
  className?: string
}

const defaultColor = 'bg-blue-600 hover:bg-blue-800'
const primaryColor = 'bg-green-600 hover:bg-green-800'

export const Button: React.FC<ButtonProps> = ({
  children,
  primary,
  className,
  ...props
}) => (
  <button
    {...props}
    className={cn(
      `rounded-md px-4 py-2 font-semibold text-white shadow-md disabled:cursor-not-allowed disabled:opacity-30 ${
        primary ? primaryColor : defaultColor
      } ${className ?? ''}`,
    )}
  >
    {children}
  </button>
)
