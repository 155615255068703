import { EventLabel } from 'common/ontology'
import { SOUND_DURATION } from 'common/utils'
import React from 'react'
import { verticalBlockStyle } from 'shared/components/Predictions'
import { MetricKey } from 'shared/types/victoria'
import { cn } from 'shared/utils/web/cn'
import { EVENT_LAYER1_METRICS, JANUS_METRICS } from 'shared/utils/web/metrics'
import { BaseTimeline } from '../../components/BaseTimeline'
import { useEventChartsData } from '../../hooks/useEventChartsData'
import { usePredictions } from '../../hooks/usePredictions'
import { EventCharts } from './EventCharts'
import { useEventContext } from './EventProvider'

const IMPORTANT_METRICS: Record<EventLabel, MetricKey> = {
  chute: 'aws_janus_interpolation_Chute',
  vomissement: 'aws_janus_interpolation_Vomissement',
  sortie: 'aws_janus_interpolation_Sortie',
}

type Props = {
  serial: string
  start: number
  end: number
}

type MarkerProps = {
  ts: number
  start: number
  end: number
  label: string
  phase: string
}

function computeHeight(phase: MarkerProps['phase']) {
  switch (phase) {
    case 'medium':
      return 'top-1/2 -translate-y-1/2'
    case 'low':
      return 'top-3/4 -translate-y-1/2'
    case 'high':
      return 'top-1/4 -translate-y-1/2'
    case 'end':
      return ''
    case 'start':
    default:
      return 'top-full -translate-y-full'
  }
}

const Marker = ({ label, ts, start, end, phase }: MarkerProps) => {
  const left = Math.max(ts - SOUND_DURATION, start)
  const right = Math.min(ts, end)

  const range = end - start

  return (
    <div
      className="absolute inset-y-0 z-20 ring-1 ring-inset ring-orange-400"
      style={verticalBlockStyle(left, right, start, range)}
    >
      <div
        className={cn(
          'absolute left-1/2 -translate-x-1/2 rounded-xl bg-orange-400 p-2 font-bold',
          computeHeight(phase),
        )}
      >
        {label}
      </div>
    </div>
  )
}

const TimeLine = ({ serial, start, end }: Props) => {
  const {
    editedPhase,
    handleKeyPressed,
    setSelectedTimestamp,
    selectedTimestamp,
    selectedLabel,
  } = useEventContext()

  const { predictions } = usePredictions(
    Object.keys(JANUS_METRICS),
    serial,
    start,
    end,
  )
  const chartsData = useEventChartsData(predictions, JANUS_METRICS, {
    start,
    end,
  })

  const importantMetric = selectedLabel
    ? IMPORTANT_METRICS[selectedLabel]
    : null

  return (
    <BaseTimeline
      isScaleEnabled={false}
      serial={serial}
      start={start}
      end={end}
      metrics={EVENT_LAYER1_METRICS}
      onKeyPressed={handleKeyPressed}
      setSelectedTimestamp={setSelectedTimestamp}
      selectedTimestamp={selectedTimestamp}
      renderAdditionalElements={() => (
        <>
          {editedPhase?.start && typeof editedPhase.start === 'number' && (
            <Marker
              label="Début"
              ts={editedPhase.start}
              phase="start"
              {...{ start, end }}
            />
          )}
          {editedPhase?.low && typeof editedPhase.low === 'number' && (
            <Marker
              label="Faible"
              ts={editedPhase.low}
              phase="low"
              {...{ start, end }}
            />
          )}
          {editedPhase?.medium && typeof editedPhase.medium === 'number' && (
            <Marker
              label="Moyen"
              ts={editedPhase.medium}
              phase="medium"
              {...{ start, end }}
            />
          )}
          {editedPhase?.high && typeof editedPhase.high === 'number' && (
            <Marker
              label="Élevé"
              ts={editedPhase.high}
              phase="high"
              {...{ start, end }}
            />
          )}
          {editedPhase?.end && typeof editedPhase.end === 'number' && (
            <Marker
              label="Fin"
              ts={editedPhase.end}
              phase="end"
              {...{ start, end }}
            />
          )}
        </>
      )}
      charts={
        <EventCharts
          metrics={JANUS_METRICS}
          data={chartsData}
          start={start}
          end={end}
          importantMetric={importantMetric}
          handleChartsClick={setSelectedTimestamp}
        />
      }
    />
  )
}
export const EventTimeLine = React.memo(TimeLine)
