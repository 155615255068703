import { useMemo } from 'react'
import { MetricsConfig } from 'shared/utils/web/metrics'
import { Predictions } from './usePredictions'

const ALLOWED_METRICS = [
  'aws_janus_interpolation_AS_Presence',
  'aws_janus_interpolation_Sleeping',
  'aws_janus_interpolation_Television',
  'aws_janus_interpolation_Sortie',
  'aws_janus_interpolation_Chute',
  'aws_janus_interpolation_Appel_urgent',
  'aws_janus_interpolation_Appel_confort',
  'aws_janus_interpolation_Crise_agitation',
  'aws_janus_interpolation_Absent',
  'aws_janus_interpolation_Present',
  'aws_janus_interpolation_Vomissement',
  'aws_janus_interpolation_Detresse_respiratoire',
  'aws_janus_interpolation_Souffrance_respiratoire',
  'aws_janus_interpolation_Besoin_assistance',
  'aws_janus_interpolation_Deambulation',
  'aws_janus_interpolation_Entree',
]

type Range = { start: number; end: number }

const processDataForCharts = (
  data: Predictions,
  metrics: MetricsConfig,
  range: Range,
) => {
  const { start, end } = range
  // Filtrer les métriques autorisées
  const allowedMetricsKeys = Object.keys(metrics).filter((key) =>
    ALLOWED_METRICS.includes(key),
  )

  const seriesData = Object.entries(data).map(([timestamp, values]) => {
    const seriesObject: { timestamp: number; [key: string]: number } = {
      timestamp: Number(timestamp),
    }

    values.forEach((value, index) => {
      // Ajouter les valeurs uniquement pour les métriques autorisées
      const metricKey = Object.keys(metrics)[index]
      if (value && allowedMetricsKeys.includes(metricKey)) {
        seriesObject[metricKey] = Math.round(value * 100) / 100
      }
    })

    return seriesObject
  })

  // Inject start and end timestamps only if needed
  return [
    ...(seriesData.some((d) => d.timestamp === start)
      ? []
      : [{ timestamp: start }]),
    ...seriesData,
    ...(seriesData.some((d) => d.timestamp === end)
      ? []
      : [{ timestamp: end }]),
  ]
}

export const useEventChartsData = (
  predictions: Predictions,
  metrics: MetricsConfig,
  range: Range,
) => {
  const data = useMemo(
    () => processDataForCharts(predictions, metrics, range),
    [metrics, predictions, range],
  )

  return data
}
