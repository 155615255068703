import { Dialog as SharedDialog } from 'shared/components/Dialog'
import { Button } from './Button'

type DialogProps = {
  onClose: () => void
  onValidate: () => void
  children: React.ReactNode
}
export const Dialog = ({ onClose, onValidate, children }: DialogProps) => {
  function handleValidate() {
    onValidate()
    onClose()
  }

  return (
    <SharedDialog onClose={onClose}>
      <div className="bg-background flex flex-col gap-4 rounded-md p-4">
        {children}
        <div className="flex flex-row justify-evenly gap-2">
          <Button onClick={onClose}>Annuler</Button>
          <Button primary onClick={handleValidate}>
            Valider
          </Button>
        </div>
      </div>
    </SharedDialog>
  )
}
