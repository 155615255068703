import { LABEL_EVENT_TYPE } from 'common/ontology'
import { DateTime } from 'luxon'
import { formatMsTime } from 'shared/utils/time'
import { Button } from '../../components/Button'
import { useEventContext } from './EventProvider'

function formatMsTimeWithSeconds(milliseconds: number) {
  return DateTime.fromMillis(milliseconds)
    .setZone('Europe/Paris')
    .toLocaleString(DateTime.TIME_24_WITH_SECONDS)
}

const TimestampCard = ({
  name,
  phaseKey,
  keyboardKey,
  range,
}: {
  name: string
  phaseKey: 'end' | 'start' | 'low' | 'medium' | 'high'
  keyboardKey: string
  range: { start: number; end: number }
}) => {
  const { start, end } = range
  const formmattedEventStart = formatMsTime(start)
  const formmattedEventEnd = formatMsTime(end)

  const {
    editedPhase,
    setEditedPhase,
    selectedLabel,
    selectedTimestamp: timestamp,
  } = useEventContext()

  if (selectedLabel === null) return null

  return (
    <div
      className={`flex w-64 flex-col items-center gap-2 rounded-md border-2 border-black p-2 text-sm ${editedPhase?.[phaseKey] !== undefined && 'bg-orange-400'}`}
    >
      {name} ({keyboardKey})
      <div>
        {editedPhase && editedPhase[phaseKey] ? (
          <div className="text-2xl">
            {editedPhase[phaseKey] === 'before'
              ? `Avant ${formmattedEventStart}`
              : editedPhase[phaseKey] === 'after'
                ? `Après ${formmattedEventEnd}`
                : // FIXME AS NUMBER
                  formatMsTimeWithSeconds(editedPhase[phaseKey] as number)}
          </div>
        ) : (
          <Button
            disabled={!timestamp}
            onClick={() => {
              setEditedPhase({
                ...editedPhase,
                [phaseKey]: timestamp,
                label: selectedLabel,
              })
            }}
            title={`Placer la tête de lecture sur la particule voulue et appuyer sur [${keyboardKey}] pour placer le marqueur`}
          >
            Particule selectionnée
          </Button>
        )}
      </div>
      {editedPhase &&
        (editedPhase[phaseKey] === undefined ? (
          phaseKey === 'end' ? (
            <Button
              onClick={() => setEditedPhase({ ...editedPhase, end: 'after' })}
              title={`Indiquer que le marqueur se trouve après ${formmattedEventEnd}`}
            >
              Après {formmattedEventEnd}
            </Button>
          ) : (
            <Button
              onClick={() =>
                setEditedPhase({ ...editedPhase, [phaseKey]: 'before' })
              }
              title={`Indiquer que le marqueur se trouve avant ${formmattedEventStart}`}
            >
              Avant {formmattedEventStart}
            </Button>
          )
        ) : (
          <div>
            <Button
              className="bg-red-500 text-white"
              onClick={() => {
                const updatedEvent = { ...editedPhase }
                delete updatedEvent[phaseKey]
                setEditedPhase(updatedEvent)
              }}
              title="Réinitialiser"
            >
              Réinitialiser
            </Button>
          </div>
        ))}
    </div>
  )
}

const Container: React.FC<
  React.PropsWithChildren<{
    title: string
    errorMessage: string
  }>
> = ({ children, errorMessage }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-center text-xl">Début instantané</div>
      <div className="flex flex-row gap-2">{children}</div>
      <div className="text-red-500">{errorMessage}</div>
    </div>
  )
}

export const TimeStampContainer = ({
  range,
}: {
  range: { start: number; end: number }
}) => {
  const { selectedLabel, errorMessage } = useEventContext()

  if (selectedLabel === null)
    return (
      <div className="flex grow flex-col items-center justify-center">
        Selectionner un label
      </div>
    )

  if (LABEL_EVENT_TYPE[selectedLabel] === 'abruptStart')
    return (
      <Container title="Début instantané" errorMessage={errorMessage}>
        <TimestampCard
          name="Début"
          keyboardKey="D"
          phaseKey="start"
          range={range}
        />
        <TimestampCard
          name="Fin"
          keyboardKey="F"
          phaseKey="end"
          range={range}
        />
      </Container>
    )
  return (
    <Container title="Début progressif" errorMessage={errorMessage}>
      <TimestampCard
        name="Faible"
        keyboardKey="A"
        phaseKey="low"
        range={range}
      />
      <TimestampCard
        name="Moyen"
        keyboardKey="Z"
        phaseKey="medium"
        range={range}
      />
      <TimestampCard
        name="Elevé"
        keyboardKey="E"
        phaseKey="high"
        range={range}
      />
      <TimestampCard name="Fin" keyboardKey="F" phaseKey="end" range={range} />
    </Container>
  )
}
