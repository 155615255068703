import { SCOPE_AVG_STEP_MAPPING } from 'common/utils'
import { QueueItem } from 'shared/hooks/useQueue'
import { s3BucketName } from 'shared/types/aws'
import { DateString, FirebaseKey, UserId } from 'shared/types/utils'
import { S3Sound } from 'shared/utils/web/fetchData'
import { z } from 'zod'

type Prefix = string
// undefined = prefix loading is not yet started
// null = prefix loading is in progress
export type PrefixDataMap = Record<Prefix, S3Sound[] | undefined | null>

export interface InstantLabels {
  labels: string[]
  ts: number
  start_ts: number
}

type UserInstantLabels = Record<UserId, InstantLabels>

interface Base {
  serial: string
  source: string
  why: string
}

export interface LabeledInstant extends Base {
  instant: string
  labelers: UserInstantLabels
}

type Stats = {
  count: number
}

export type DateStats = Record<DateString, Stats>

export interface Sequence extends Base, QueueItem {
  instants: string[]
  hint?: string
}

export type Sequences = Record<FirebaseKey, Sequence>

export const SCOPES = ['short', 'mid', 'long'] as const

export type Scope = (typeof SCOPES)[number]

export const AddInstantsToLabelingQueueSchema = z.array(
  z.object({
    instants: z.string().array().nonempty(),
    source: z.string(),
    why: z.string(),
    priority: z.coerce.number().nonnegative(),
    labelersTarget: z.coerce.number().positive(),
    avgStep: z.coerce
      .number()
      .positive()
      .optional()
      .default(SCOPE_AVG_STEP_MAPPING['mid']),
    hint: z.string().optional(),
    addRandomOffsetToRef: z.coerce.boolean().optional().default(false),
  }),
)

export type AddInstantsToLabelingQueueParameters = z.infer<
  typeof AddInstantsToLabelingQueueSchema
>

const BUCKET_NAMES = [s3BucketName] as const

export type BucketName = (typeof BUCKET_NAMES)[number]
export type PhaseType = 'abruptStart' | 'gradualStart'

export type Phase = {
  label: string
  start?: number | 'before'
  low?: number | 'before'
  medium?: number | 'before'
  high?: number | 'before'
  end?: number | 'after'
}

export interface EventLabels {
  labels: Phase[]
  ts: number
  start_ts: number
}

type UserEventLabels = Record<UserId, EventLabels>

interface BaseEvent extends Base {
  eventSeed: number
  eventStart: number
  eventEnd: number
}

export interface LabeledEvent extends BaseEvent {
  labelers: UserEventLabels
}

export interface Event extends BaseEvent {
  labelerUids?: string[]
  priority: number
  sortIndex: number
  labelersTarget: number
  hint?: string
}

export type Events = Record<FirebaseKey, Event>

export const AddEventToLabelingQueueSchema = z.array(
  z.object({
    instants: z.string().array().nonempty(),
    source: z.string(),
    why: z.string(),
    priority: z.coerce.number().nonnegative(),
    labelersTarget: z.coerce.number().positive(),
  }),
)

export type AddEventsToLabelingQueueParameters = z.infer<
  typeof AddEventToLabelingQueueSchema
>
