import React, { createContext, useContext, useState } from 'react'
import { MAX_VOL } from 'shared/utils/web/audio'

export type Settings = {
  contrast: number
  setContrast: React.Dispatch<React.SetStateAction<number>>
  admissibleVolume: number
  setAdmissibleVolume: React.Dispatch<React.SetStateAction<number>>
  volume: number
  setVolume: React.Dispatch<React.SetStateAction<number>>
  scale: number
  setScale: React.Dispatch<React.SetStateAction<number>>
  autoVolumeAdjust: boolean
  setAutoVolumeAdjust: React.Dispatch<React.SetStateAction<boolean>>
  playbackRate: number
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>
}

const SettingsContext = createContext<Settings | undefined>(undefined)

export const SettingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [contrast, setContrast] = useState(50)
  const [scale, setScale] = useState(1)
  const [admissibleVolume, setAdmissibleVolume] = useState(MAX_VOL)
  const [volume, setVolume] = useState(MAX_VOL)
  const [autoVolumeAdjust, setAutoVolumeAdjust] = useState(true)
  const [playbackRate, setPlaybackRate] = useState(1)

  const options = {
    contrast,
    setContrast,
    admissibleVolume,
    setAdmissibleVolume,
    volume,
    setVolume,
    scale,
    setScale,
    autoVolumeAdjust,
    setAutoVolumeAdjust,
    playbackRate,
    setPlaybackRate,
  }
  return (
    <SettingsContext.Provider value={options}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettings = () => {
  const option = useContext(SettingsContext)
  if (option === undefined) {
    throw new Error('useSettings must be used within an SettingsProvider')
  }

  return option
}
