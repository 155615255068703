type Props = {
  percent: number
}

export const PlayCursor = ({ percent }: Props) => {
  return (
    <div
      className="absolute inset-y-0 z-10 -ml-0.5 w-0.5 bg-blue-500"
      style={{ left: `${percent}%` }}
    />
  )
}
