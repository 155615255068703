import { computePercent } from 'common/utils'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { ms } from 'shared/utils/time'
import { defaultMetrics } from 'shared/utils/web/metrics'
import { BaseTimeline } from '../../components/BaseTimeline'
import { MAX_DURATION } from '../../components/MediaController'

const INITIAL_DURATION = ms(10, 'minutes')
const DURATION_DELTA = ms(5, 'minutes')

type SequenceTimeLineProps = {
  sequenceKey: string
  serial: string
  instants: string[]
  sequenceIndex: number
}

const TimeLine: React.FC<SequenceTimeLineProps> = ({
  sequenceKey,
  serial,
  instants,
  sequenceIndex,
}) => {
  const instantsTs = React.useMemo(
    () => instants.map((instant) => new Date(instant).valueOf()),
    [instants],
  )

  const referenceInstant = React.useMemo(
    () =>
      new Date(sequenceKey.substring(sequenceKey.indexOf('-') + 1)).valueOf(),
    [sequenceKey],
  )

  const [start, setStart] = useState(
    DateTime.fromMillis((instantsTs.at(0) ?? 0) - INITIAL_DURATION)
      .startOf('minute') // since fetchData has a minute resolution
      .valueOf(),
  )

  const [end, setEnd] = useState(instantsTs.at(sequenceIndex) ?? 0)

  function handleIncreaseDuration() {
    setStart((start) => Math.max(start - DURATION_DELTA, end - MAX_DURATION))
  }

  function adjustEnd(newEnd: number) {
    setEnd(newEnd)
  }

  // Extend end when sequenceIndex changes
  useEffect(() => {
    setEnd(instantsTs.at(sequenceIndex) ?? 0)
  }, [instantsTs, sequenceIndex])

  const renderAdditionalElements = React.useCallback(
    () => (
      <>
        {instantsTs.slice(0, sequenceIndex).map((instant) => (
          <div
            key={instant}
            className="absolute inset-y-0 -ml-0.5 w-0.5 bg-rose-600 bg-opacity-50"
            style={{
              left: `${computePercent(instant, start, end)}%`,
            }}
            title="Instant précédemment labellisé"
          />
        ))}
        <div
          className="absolute inset-y-0 -ml-0.5 w-0.5 bg-rose-600"
          style={{
            left: `100%`,
          }}
          title="Instant à labelliser"
        />
        {referenceInstant <= instantsTs[sequenceIndex] && (
          <div
            className="absolute inset-y-0 -ml-1 w-0.5 bg-amber-400"
            style={{
              left: `${computePercent(referenceInstant, start, end)}%`,
            }}
            title="Instant de référence"
          />
        )}
      </>
    ),
    [start, end, instantsTs, sequenceIndex, referenceInstant],
  )

  return (
    <BaseTimeline
      isScaleEnabled
      serial={serial}
      start={start}
      end={end}
      metrics={defaultMetrics}
      renderAdditionalElements={renderAdditionalElements}
      sequenceProps={{ handleIncreaseDuration, sequenceIndex, instants }}
      adjustEnd={adjustEnd}
    />
  )
}

export const SequenceTimeLine = React.memo(TimeLine)
