import { MAX_VOL, MIN_VOL } from 'shared/utils/web/audio'
import { useSettings } from '../SettingsProvider'
import { Button } from './Button'

export const VolumeController = () => {
  const {
    autoVolumeAdjust,
    setAutoVolumeAdjust,
    volume,
    admissibleVolume,
    setVolume,
  } = useSettings()

  return (
    <div className="flex flex-row gap-2">
      <Button
        className={`flex gap-2 rounded-full  ${
          autoVolumeAdjust ? '' : '!bg-red-600'
        }`}
        onClick={() =>
          autoVolumeAdjust
            ? setAutoVolumeAdjust(false)
            : setAutoVolumeAdjust(true)
        }
      >
        <span className="text-2xl" role="img" aria-label="Volume">
          {volume > 0.66 ? '🔊' : volume > 0.33 ? '🔉' : '🔈'}
        </span>
        <span className="self-center">
          {autoVolumeAdjust ? 'Auto' : 'Manuel'}
        </span>
      </Button>
      <input
        className={`flex-1 ${
          volume - admissibleVolume > 0.2
            ? 'accent-red-600'
            : volume - admissibleVolume > 0
              ? 'accent-yellow-300'
              : ''
        }`}
        type="range"
        value={volume}
        min={MIN_VOL}
        max={MAX_VOL}
        step={0.01}
        onChange={(e) => setVolume(parseFloat(e.target.value))}
        title="Réglage du volume"
      />
    </div>
  )
}
