import { PrefixDataMap } from 'common/types'
import { S3Sound } from 'shared/utils/web/fetchData'

type Props = {
  currentSoundKey: string
  soundTexts: Record<string, string | undefined | null>
  data: S3Sound[]
  prefixDataMap: PrefixDataMap
}
export const SoundOverlay = ({
  currentSoundKey,
  soundTexts,
  data,
  prefixDataMap,
}: Props) => {
  return (
    <div className="pointer-events-none absolute inset-0 flex flex-col items-center justify-center">
      {currentSoundKey && soundTexts[currentSoundKey] && (
        <div className="rounded-md bg-slate-500 bg-opacity-50 px-4 py-1 italic">
          {`“${soundTexts[currentSoundKey]}”`}
        </div>
      )}
      {Object.values(prefixDataMap).some(
        (prefixData) => prefixData === undefined || prefixData === null,
      ) ? (
        <div className="rounded-md bg-slate-500 bg-opacity-50 px-4 py-1">
          Chargement...
        </div>
      ) : data.length === 0 ? (
        <div className="rounded-md bg-slate-500 bg-opacity-50 px-4 py-1">
          Aucun son sur l'intervalle selectionné
        </div>
      ) : null}
    </div>
  )
}
