import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'
import { MetricsConfig } from 'shared/utils/web/metrics'
import { formatTime } from '../../TimeMarks'

const TOOLTIP_THRESHOLD = 0.05

type DataProps = {
  [key: string]: number
  timestamp: number
}
type ChartsProps = {
  data: DataProps[]
  metrics: MetricsConfig
  start: number
  end: number
  importantMetric: string | null
  handleChartsClick: (ts: number) => void
}

export const EventCharts = ({
  data,
  metrics,
  start,
  end,
  importantMetric,
  handleChartsClick,
}: ChartsProps) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={275}
      className="mt-2 overflow-hidden"
    >
      <LineChart
        data={data}
        margin={{ right: 0, left: 0 }}
        onClick={(e) => {
          if (e.activeLabel) handleChartsClick(Number(e.activeLabel))
        }}
      >
        {Object.entries(metrics)
          .sort((_, [nameB]) =>
            // Ensure importantMetric is first
            nameB === importantMetric ? -1 : 1,
          )
          .map(([name, { color, label }]) => (
            <Line
              key={name}
              // type=""
              dataKey={name}
              stroke={
                importantMetric
                  ? importantMetric === name
                    ? color
                    : `${color}80` // 80 is hex code for 50% opacity
                  : color
              }
              strokeWidth={importantMetric === name ? 10 : 3}
              dot={{
                fill: color,
              }}
              name={label}
              isAnimationActive={false}
              activeDot={{ r: 8, strokeWidth: 2 }}
            />
          ))}
        <YAxis domain={[0, 1]} mirror />
        <XAxis
          scale="time"
          dataKey="timestamp"
          tickFormatter={(tick) => formatTime(tick)}
          hide
          domain={[start, end]}
        />
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid vertical={false} strokeDasharray="2 20" />
        <Legend
          verticalAlign="top"
          iconSize={0}
          wrapperStyle={{ paddingBottom: '20px' }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

const CustomTooltip = ({
  active,
  payload,
}: TooltipProps<ValueType, NameType>) => {
  if (!active || !payload?.length) return null

  const filteredAndSortedPayload = payload
    .filter(
      (p) =>
        typeof p.value === 'number' && (p.value as number) >= TOOLTIP_THRESHOLD,
    )
    .sort((a, b) => (b.value as number) - (a.value as number))

  if (!filteredAndSortedPayload.length) return null

  return (
    <div className="bg-background rounded-md p-2">
      {filteredAndSortedPayload.map((p) => (
        <div key={p.name} className="text-sm">
          <span style={{ color: p.color }} className="font-bold">
            {p.name}
          </span>{' '}
          : {p.value}
        </div>
      ))}
    </div>
  )
}
