import { MetricKey } from '../../types/victoria'

export const metricPrefixes = [
  'aws_layer1_',
  'aws_dionysos_',
  'aws_alertor_',
  'aws_janus_interpolation_',
]

export function metricDisplayName(metric: MetricKey) {
  if (metric in defaultMetrics) return defaultMetrics[metric].label

  return metricPrefixes.reduce((acc, prefix) => acc.replace(prefix, ''), metric)
}

export type MetricsConfig = Record<MetricKey, { label: string; color: string }>

// These names must match the metrics names defined in Victoria Metrics
export const defaultMetrics: MetricsConfig = {
  aws_layer1_Parole: {
    label: 'Parole',
    color: 'lime',
  },
  aws_layer1_Ronflement: {
    label: 'Ronflement',
    color: 'yellow',
  },
  aws_layer1_Toux: {
    label: 'Toux',
    color: 'red',
  },
  aws_layer1_AS: {
    label: 'AS',
    color: 'white',
  },
  'aws_layer1_Eau-toilettes': {
    label: 'Eau, toilettes',
    color: 'blue',
  },
  'aws_layer1_Bruit de pas': {
    label: 'Bruit de pas',
    color: 'fuchsia',
  },
  aws_layer1_Porte: {
    label: 'Porte',
    color: 'tomato',
  },
  aws_layer1_Brouhaha: {
    label: 'Brouhaha',
    color: 'gray',
  },
  aws_layer1_Respiration: {
    label: 'Respiration',
    color: 'olivedrab',
  },
  'aws_layer1_Lit draps': {
    label: 'Lit draps',
    color: 'turquoise',
  },
  'aws_layer1_Deux personnes ou plus': {
    label: '2 pers.',
    color: 'mediumspringgreen',
  },
  aws_layer1_Choc: {
    label: 'Choc',
    color: 'orange',
  },
  aws_layer1_Tambourinage: {
    label: 'Tambourinage',
    color: 'lightblue',
  },
  aws_layer1_Cri: {
    label: 'Cri',
    color: 'deeppink',
  },
  aws_layer1_Gemissement: {
    label: 'Gémissement',
    color: 'pink',
  },
  aws_layer1_Vomissement: {
    label: 'Vomissement',
    color: 'forestgreen',
  },
  'aws_layer1_Difficulte respiratoire': {
    label: 'Difficulté respiratoire',
    color: 'mediumslateblue',
  },
  aws_layer1_Television: {
    label: 'Télévision',
    color: 'LightGray',
  },
}

export const FILTERED_EVENTS_LAYER1_DEFAULT_METRICS = [
  'aws_layer1_Choc',
  'aws_layer1_Bruit de pas',
  'aws_layer1_Eau-toilettes',
  'aws_layer1_Porte',
  'aws_layer1_Vomissement',
  'aws_layer1_Gemissement',
]

export function filterMetricsByKey(
  metrics: MetricsConfig,
  filteredKeys: string[],
): MetricsConfig {
  return Object.fromEntries(
    Object.entries(metrics).filter(([key]) => filteredKeys.includes(key)),
  )
}

export const EVENT_LAYER1_METRICS = {
  ...filterMetricsByKey(defaultMetrics, FILTERED_EVENTS_LAYER1_DEFAULT_METRICS),
  'aws_layer1_Choc violent': { label: 'Choc violent', color: '#ff8c00' },
  'aws_layer1_Chute de personne': { label: 'Chute de personne', color: 'red' },
  'aws_layer1_Bruit de pas resident': {
    label: 'Bruit de pas resident',
    color: '#e11b72',
  },
  'aws_layer1_Bruit de pas AS': { label: 'Bruit de pas AS', color: '#f398c0' },
}

export const JANUS_METRICS: MetricsConfig = {
  aws_janus_interpolation_Present: {
    label: 'Résident présent',
    color: '#640b0b',
  },
  aws_janus_interpolation_Entree: {
    label: 'Entrée',
    color: '#5C4033',
  },
  aws_janus_interpolation_Sortie: {
    label: 'Sortie',
    color: '#C4A484	',
  },
  aws_janus_interpolation_Appel_confort: {
    label: 'Appel confort',
    color: '#730273',
  },
  aws_janus_interpolation_Appel_urgent: {
    label: 'Appel urgent',
    color: '#f5459d',
  },
  aws_janus_interpolation_Chute: {
    label: 'Chute',
    color: '#EE4B2B',
  },
  aws_janus_interpolation_Cris: {
    label: 'Cris',
    color: '#7B3F00',
  },
  aws_janus_interpolation_Crise_agitation: {
    label: 'Crise agitation',
    color: '#8b1ddb',
  },
  aws_janus_interpolation_Besoin_assistance: {
    label: 'Besoin assistance',
    color: '#ffff00',
  },
  aws_janus_interpolation_Monologue: {
    label: 'Monologue',
    color: '#040482',
  },
  aws_janus_interpolation_Vomissement: {
    label: 'Nausee',
    color: '#6ca805',
  },
  aws_janus_interpolation_Sleeping: {
    label: 'Sommeil',
    color: '#0000ae',
  },
  aws_janus_interpolation_AS_Presence: {
    label: 'Présence AS',
    color: '#ffffff',
  },
  aws_janus_interpolation_Detresse_respiratoire: {
    label: 'Détresse respiratoire',
    color: '#ff1000',
  },
  aws_janus_interpolation_Souffrance_respiratoire: {
    label: 'Souffrance respiratoire',
    color: '#ff6347',
  },
  aws_janus_interpolation_Besoin_attention_particuliere: {
    label: 'Besoin attention particulière',
    color: '#7fff00',
  },
  aws_janus_interpolation_Salle_de_bain: {
    label: 'Salle de bain',
    color: '#add8e6',
  },
  aws_janus_interpolation_Presence_autre_resident: {
    label: 'Présence autre résident',
    color: '#708090',
  },
}
