import { EventLabel, SequenceLabel } from 'common/ontology'
import { useState } from 'react'
import { useDebounce } from 'shared/hooks/useDebounce'
import { Button } from './Button'

type AnyLabel = EventLabel | SequenceLabel
type LabelGroup<T extends AnyLabel> = {
  title: string
  labels: T[]
}

type BaseLabelProps<T extends AnyLabel> = {
  labels: LabelGroup<T>[]
  labelText: Record<T, string>
  labelDescription: Record<T, string | JSX.Element>
  selectedLabels: T[]
  prevSelectedLabels?: T[]
  onLabelClick: (label: T) => void
  showDescriptions: boolean
}

export const BaseLabels = <T extends AnyLabel>({
  labels,
  labelText,
  labelDescription,
  selectedLabels,
  prevSelectedLabels = [],
  onLabelClick,
  showDescriptions,
}: BaseLabelProps<T>) => {
  const [hoveredGroupIndex, setHoveredGroupIndex] = useState<number>()
  const [hoveredLabel, setHoveredLabel] = useState<T>()
  const debouncedHoveredGroupIndex = useDebounce(hoveredGroupIndex, 800)

  return (
    <div className="flex overflow-y-auto">
      <div className="flex flex-1 flex-col justify-evenly">
        <div className="flex flex-row flex-wrap justify-evenly gap-x-8 gap-y-4 p-4 pt-12">
          {labels.map(({ title, labels }, index) => (
            <div
              key={title}
              className="relative flex flex-row flex-wrap justify-evenly gap-2 rounded-lg border border-slate-500 bg-sky-800 bg-opacity-40 p-2 hover:bg-sky-700 hover:shadow-md hover:shadow-gray-800"
              onMouseEnter={() => setHoveredGroupIndex(index)}
              onMouseLeave={() => setHoveredGroupIndex(undefined)}
            >
              {hoveredGroupIndex === index && (
                <div className="absolute -top-5 rounded-lg bg-sky-700 px-6 font-semibold text-white">
                  {title}
                </div>
              )}
              {labels.map((label) => (
                <Button
                  key={label}
                  className={
                    prevSelectedLabels.includes(label)
                      ? 'border-2 border-dashed border-white'
                      : ''
                  }
                  primary={selectedLabels.includes(label)}
                  onClick={() => onLabelClick(label)}
                  onMouseEnter={() => setHoveredLabel(label)}
                  onMouseLeave={() => setHoveredLabel(undefined)}
                >
                  {labelText[label]}
                </Button>
              ))}
            </div>
          ))}
        </div>
      </div>
      {showDescriptions && debouncedHoveredGroupIndex !== undefined && (
        <div className="absolute top-0 z-20 w-full bg-opacity-50 bg-gradient-to-b from-black/50 via-black/50 px-12 py-8 pb-32">
          <div className="w-full rounded-lg bg-sky-700 px-2 py-2 shadow-lg shadow-gray-800">
            <h1 className="pb-2 text-center text-lg font-bold">
              {labels[debouncedHoveredGroupIndex].title}
            </h1>
            {labels[debouncedHoveredGroupIndex].labels.map((label) => (
              <div
                key={label}
                className={`px-3 ${
                  label === hoveredLabel ? 'rounded-sm bg-blue-400' : ''
                }`}
              >
                <span className="font-bold">{labelText[label]}</span> :{' '}
                {labelDescription[label]}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
