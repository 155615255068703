import { PrefixDataMap } from 'common/types'
import { SOUND_DURATION } from 'common/utils'
import { DateTime } from 'luxon'
import { Predictions, verticalBlockStyle } from 'shared/components/Predictions'
import { ms } from 'shared/utils/time'
import { MetricsConfig } from 'shared/utils/web/metrics'
import { usePredictions } from '../hooks/usePredictions'
import { useSettings } from '../SettingsProvider'

const BUCKET_DURATION = ms(1, 'minute')

type Props = {
  prefixDataMap: PrefixDataMap
  end: number
  start: number
  soundTexts: Record<string, string | undefined | null>
  serial: string
  metrics: MetricsConfig

  // Event
  setSelectedTimestamp?: ((timestamp: number) => void) | undefined
  selectedTimestamp?: number | null | undefined
}

export const PredictionsTimeline = ({
  prefixDataMap,
  end,
  start,
  soundTexts,
  serial,
  metrics,
  setSelectedTimestamp,
  selectedTimestamp,
}: Props) => {
  const { predictions } = usePredictions(
    Object.keys(metrics),
    serial,
    start,
    end,
  )

  const { contrast } = useSettings()

  return Object.entries(prefixDataMap).map(([prefix, data]) => {
    if (!data) {
      const left = DateTime.fromISO(prefix).valueOf()
      const right = Math.min(left + BUCKET_DURATION, end)
      return (
        <div
          key={prefix}
          className="absolute inset-y-0 bg-slate-400 bg-opacity-30"
          style={verticalBlockStyle(left, right, start, end - start)}
        />
      )
    } else {
      return data.map((soundData) => {
        const ts = soundData.endTimestamp
        const left = Math.max(ts - SOUND_DURATION, start)
        const right = Math.min(ts, end)
        if (right < left) return null
        return (
          <Predictions
            selected={selectedTimestamp === soundData.endTimestamp}
            onSelected={() => {
              if (setSelectedTimestamp) {
                setSelectedTimestamp(soundData.endTimestamp)
              }
            }}
            key={soundData.soundKey}
            left={left}
            right={right}
            start={start}
            end={end}
            contrast={contrast / 100}
            text={soundTexts[soundData.soundKey]}
            predictions={predictions[ts]}
            metricsConfig={metrics}
          />
        )
      })
    }
  })
}
