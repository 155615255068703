import { PhaseType } from './types'

export const EVENT_ONTOLOGY = ['chute', 'vomissement', 'sortie'] as const

export const SEQUENCE_ONTOLOGY = [
  'a_chute',
  'absent',
  'appel_a_l_aide',
  'appel_de_confort',
  'as_au_loin',
  'aux_toilettes',
  'besoin_assistance_urgent',
  'besoin_attention_particuliere',
  'bruit_exterieur',
  'change',
  'comportement_pathologique',
  'crise_agitation',
  'dans_la_piece',
  'dans_une_piece_voisine',
  'deambulation',
  'detresse_respiratoire',
  'essaie_aller_ailleurs',
  'est_au_sol',
  'fait_sa_toilette',
  'gemissements_repetes',
  'hurlements_repetes',
  'intrusion_autre_resident',
  'joyeux',
  'lit',
  'machine',
  'mange',
  'menage',
  'monologue',
  'parle_a_qqun',
  'parle_au_telephone',
  'parle_seul',
  'passage_recent',
  'piece_voisine',
  'presence_as',
  'presence_autre_resident',
  'presence_famille_proche',
  'probleme_ecoute_des_sons',
  'rangement',
  'salle_de_bain',
  'se_deplace_debout',
  'silence',
  'sommeil_agite',
  'sommeil_normal',
  'sorti_accompagne',
  'sorti_seul',
  'souffrance_respiratoire',
  'stress',
  'tambourinages_repetes',
  'television',
  'toilette_au_lit',
  'toilette_sdb',
  'vient_de_rentrer_accompagne',
  'vient_de_rentrer_seul',
  'vomissement',
] as const

export const LABEL_EVENT_TYPE: Record<EventLabel, PhaseType> = {
  chute: 'abruptStart',
  vomissement: 'abruptStart',
  sortie: 'abruptStart',
}

export type EventLabel = (typeof EVENT_ONTOLOGY)[number]
export type SequenceLabel = (typeof SEQUENCE_ONTOLOGY)[number]
